import { Injectable } from '@angular/core';
import { collection, deleteField } from '@angular/fire/firestore';
import { RecursivePartial } from '@memberspot/models';
import {
  AllBox,
  BannerBox,
  BoxDiscriminator,
  CustomView,
  HeaderFooterObject,
  LoginLinks,
  MemberArea,
  MemberAreaTheme,
  SortableBoxes,
} from '@memberspot/shared/model/memberarea';
import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { from } from 'rxjs';

import { MemberAreaState, MemberAreaStore } from './member-area.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'memberareas' })
export class MemberAreaService extends CollectionService<MemberAreaState> {
  constructor(store: MemberAreaStore) {
    super(store);
  }

  setActive(schoolId: string | null) {
    this.store?.setActive(schoolId);
  }

  createId(): string {
    return doc(collection(this.db, 'id')).id;
  }

  updateMa(schoolId: string, upt: RecursivePartial<MemberArea>) {
    return setDoc(doc(this.db, `memberareas/${schoolId}`), upt, {
      merge: true,
    });
  }

  updateSingleHFObject(
    schoolId: string,
    singleObj: HeaderFooterObject,
    saveType: 'header' | 'footer',
  ) {
    const prop = saveType === 'header' ? 'headerLinks' : 'footerLinks';
    const id: string = Object.keys(singleObj)[0];
    const headerFooterLink = singleObj[id];

    const structuredObj = {
      [prop]: {
        [id]: headerFooterLink === null ? deleteField() : headerFooterLink,
      },
    };

    setDoc(doc(this.db, `memberareas/${schoolId}`), structuredObj, {
      merge: true,
    });
  }

  updateHFObject(
    schoolId: string,
    fullObj: HeaderFooterObject,
    saveType: 'header' | 'footer',
  ) {
    const prop = saveType === 'header' ? 'headerLinks' : 'footerLinks';

    return updateDoc(this.getMemberareaRef(schoolId), { [prop]: fullObj });
  }

  updateBannerBox(schoolId: string, box: BannerBox) {
    return setDoc(
      this.getMemberareaRef(schoolId),
      { bannerBox: box },
      { merge: true },
    );
  }

  updateBox(
    schoolId: string,
    id: BoxDiscriminator,
    updateObj: Partial<AllBox>,
  ) {
    const newData: Record<string, unknown> = {};

    // delete the "" properties in object
    if (updateObj !== null) {
      Object.getOwnPropertyNames(updateObj).forEach((prop) => {
        // eslint-disable-next-line no-prototype-builtins
        newData[prop] = updateObj.hasOwnProperty(prop)
          ? // eslint-disable-next-line
          updateObj[prop]
          : deleteField();
      });
    }

    // create structured object
    const structuredObj = {
      boxes: {
        [id]:
          updateObj !== null
            ? { discriminator: id, ...newData }
            : deleteField(),
      },
    };

    return setDoc(this.getMemberareaRef(schoolId), structuredObj, {
      merge: true,
    });
  }

  deleteLinkBoxLink(schoolId: string, boxDiscriminator: BoxDiscriminator) {
    const structuredObj = {
      boxes: {
        [boxDiscriminator]: {
          link: deleteField(),
        },
      },
    };

    return setDoc(this.getMemberareaRef(schoolId), structuredObj, {
      merge: true,
    });
  }

  getUniqueId() {
    return this.createId();
  }

  updateCustomView(schoolId: string, customView: CustomView) {
    return setDoc(
      this.getMemberareaRef(schoolId),
      { customView },
      { merge: true },
    );
  }

  updateClientLoginBackground(schoolId: string, imageUrl: string) {
    return setDoc(
      this.getMemberareaRef(schoolId),
      { clientLoginBackground: imageUrl },
      { merge: true },
    );
  }

  /**
   * updates the priority in firestore
   * @param schoolId school where the memberarea belongs to
   * @param newboxes reordered boxes
   */
  updateBoxesOrder(schoolId: string, newboxes: SortableBoxes[]) {
    const boxesUpt: RecursivePartial<MemberArea> = {
      boxes: {},
    };

    newboxes.forEach((box, index) => {
      if (!boxesUpt.boxes) {
        boxesUpt.boxes = {};
      }

      boxesUpt.boxes[box.discriminator] = { priority: index };
    });

    return setDoc(this.getMemberareaRef(schoolId), boxesUpt, { merge: true });
  }

  updateThemeTemp(schoolId: string, theme: Partial<MemberAreaTheme>) {
    this.store?.update(schoolId, (ma) => ({
      ...ma,
      theme: {
        ...ma?.theme,
        ...theme,
      },
    }));
  }

  updateFavicon(schoolId: string, favicon: Pick<MemberArea, 'favicon'>) {
    return setDoc(this.getMemberareaRef(schoolId), favicon, { merge: true });
  }

  updateLoginLinks(schoolId: string, loginLinks: LoginLinks) {
    return from(
      setDoc(this.getMemberareaRef(schoolId), { loginLinks }, { merge: true }),
    );
  }

  private getMemberareaRef(schoolId: string) {
    return doc(this.db, `memberareas/${schoolId}`);
  }
}
